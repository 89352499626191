import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = environment.title;
  applications = environment.applications;

  openLink(url: string){
    var splits = url.split("apps.supplyontario.ca/");
    if (splits.length > 1) {			
      url=window.location.protocol + "//" + window.location.hostname + window.location.pathname + splits[1];
      if (window.location.hostname === 'localhost') {
        url=window.location.protocol + "//" + "dev.apps.supplyontario.ca" + window.location.pathname + splits[1];
      }
    }
    
    window.open(url,'_self');
  }



}

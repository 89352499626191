// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  title: 'Enterprise IT Applications (DEV/IST)',
  applications: [
    { name: 'Contract Application', description: "Application to search vendor contracts and item pricing", link: "https://www.apps.supplyontario.ca/contract/", img : "./contract.png" },
    { name: 'SO Enterprise IT Hub', description: "Application to submit and maintain Enterprise IT Work Requests", link: "https://www.apps.supplyontario.ca/itworkrequest/", img : "./EITHub.png" },
    { name: 'Employee Heatmap', description: "Heat Map of Employee locations", link: "https://somapexample.azurewebsites.net/employeeheatmap/" , img : ".//heatmap.png"}
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

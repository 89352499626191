<mat-toolbar color="primary">
  <a href="https://supplyontario--simpplr.vf.force.com/apex/simpplr__app?u=/site/a148Z00000B2KhTQAV/dashboard" matTooltip="Go To: The Source - Enterprise IT home page."
      [matTooltipPosition]="'right'" class="custom-logo-link"><img src="./logo-en.png"
          alt="Supply Ontario Home"></a>
          <h1>{{title}}</h1>
</mat-toolbar>


<div class="row">
    
  <div *ngFor="let app of applications" class="col-md-6">
    <mat-card class="card-section-sm" (click)="openLink(app.link)" >
        <mat-card-title>{{app.name}}</mat-card-title>
        <mat-card-subtitle></mat-card-subtitle>
        <mat-card-content>
          <img *ngIf="app.img"  class="request-img" src="{{app.img}}" alt="consulation icon"
          height="866" width="860">
          <span class="app-desc">{{app.description}}</span>
        </mat-card-content>

    </mat-card>
</div>

<!--
  <div class="col-md-6">
      <mat-card class="card-section-sm" (click)="openLink('https://www.apps.supplyontario.ca/contract/')" >
          <mat-card-title>Contract Application</mat-card-title>
          <mat-card-subtitle></mat-card-subtitle>
          <mat-card-content>
            Application to search vendor contracts and item pricing
          </mat-card-content>

      </mat-card>
  </div>
  <div class="col-md-6">
    <mat-card class="card-section-sm" (click)="openLink('https://www.apps.supplyontario.ca/itworkrequest/')">
        <mat-card-title>IT Work Request Intake</mat-card-title>
        <mat-card-subtitle></mat-card-subtitle>
        <mat-card-content>
          Application to submit and maintain IT Work Requests
        </mat-card-content>

    </mat-card>
</div>
-->
</div>